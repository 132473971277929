import React, { useEffect, useState } from 'react'
import ReactDom from 'react-dom'
import MUIDataTable from 'mui-datatables'
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap"
import { Edit, AccessibilityNew, Add, Delete } from "@material-ui/icons";
import { TextField, FormControlLabel, Checkbox, Card } from '@material-ui/core'
import { useSelector, useDispatch } from "react-redux"
import GlobalLoader from "../layout/GlobalLoader"
import { getMuiTheme, getCurrentDate } from '../../Services/Common'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Autocomplete from '@mui/material/Autocomplete';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import { SelectGroupParty } from '../../Services/PartiesAPI'
import { SelectItems, SelectItemGridEdit } from "../../Services/ItemAPI"
import SaveIcon from '@mui/icons-material/Save';
import Cancel from '@mui/icons-material/Cancel';
import Print from '@mui/icons-material/Print';
import PostAddIcon from '@mui/icons-material/PostAdd';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Close } from "@material-ui/icons";
import LoadingButton from '@mui/lab/LoadingButton';
import { useHistory } from 'react-router-dom';
import { setFormData } from './TransactionSlice';
import { GenerateMaxTrNo, SaveTransaction, SelectTrDetail, SelectTrMaster, LastPurchaseRate, InvoicePost, LastAveragePurRate } from '../../Services/TransactionAPI'
import { separateNumber } from './../ThousandSeparator'
import Grid from './Grid'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        color: "white",
        backgroundColor: "#293042",
        marginTop: "5px",
        padding: "5px",
        paddingRight: "10px",
        borderRadius: "5px",
        '&:hover': {
            color: "white",
            backgroundColor: "#293042",
        },
        '&.Mui-disabled': {
            color: "white",
            backgroundColor: "#293042",
        },
    },
});

function PurchaseInvoice() {
    const dispatch = useDispatch()
    const history = useHistory();
    const FormName = useSelector(({ TransactionSlice }) => TransactionSlice.FormName)
    const StockType = useSelector(({ TransactionSlice }) => TransactionSlice.StockType)
    const AddNew = useSelector(({ TransactionSlice }) => TransactionSlice.AddNew)
    const TrID = useSelector(({ TransactionSlice }) => TransactionSlice.TrID)
    const SelectedMonth = useSelector(({ TransactionSlice }) => TransactionSlice.TrData)
    const UserID = useSelector(({ login }) => login?.user[0]?.UserID)
    const UserType = useSelector(({ login }) => login?.user[0]?.UserType)
    const CompID = useSelector(({ login }) => login.UserCompany[0]?.CompID)
    const CompName = useSelector(({ login }) => login.UserCompany[0]?.CompName)
    const CompAdd = useSelector(({ login }) => login.UserCompany[0]?.Address)
    const [ItemDetailData, setItemDetailData] = useState([])
    const [FromDate, setFromDate] = useState(getCurrentDate)
    const [PartiesList, setPartiesList] = useState([])
    const [SelectedParty, setSelectedParty] = useState(null)
    const [ItemsList, setItemsList] = useState([])
    const [SelectedItem, setSelectedItem] = useState(null)
    const [Rate, setRate] = useState(0)
    const [Qty, setQty] = useState(0)
    const [Amount, setAmount] = useState(0)
    const [TotalQty, setTotalQty] = useState(0)
    const [TotalAmount, setTotalAmount] = useState(0)
    const [loading, setLoading] = useState(false)
    const [TRDID, setTRDID] = useState(0)
    const [InvNo, setInvNo] = useState(0)
    const [IsCancel, setIsCancel] = useState(false)
    const [Remarks, setRemarks] = useState("")
    const [StkQty, setStkQty] = useState(0)
    const [LastPurchaseRateParty, setLastPurchaseRateParty] = useState("")
    const [Post, setPost] = useState(0)
    const [deleterowData, setDeleterowData] = useState([])
    const [TempItemOptions, setTempItemOptions] = useState([])
    // const [IsLoader, setIsLoader] = useState(false);
    document.title = `Stock Keeper / ${FormName === "Purchase" ? "Purchase Invoice" : "Consumption Invoice"}`
    const [ErrorMsg, setErrorMsg] = useState({ ItemError: '', QtyError: '', RateError: '', PartyError: '', TotalAmountError: '', AmountError: '' })
    const [rerender, setRerender] = useState(false)
    const [showLoader, setShowLoader] = useState(false)
    useEffect(async () => {


        if (FormName) {
            setShowLoader(true)
            const Itemresponse = await SelectItems(CompID, 0, 1)
            let _ItemsArrray = Itemresponse.data.Data
            const TrType = FormName === "Purchase" ? "Stock In" : "Stock Out"
            const PartyResponse = await SelectGroupParty(CompID, TrType, 1)
            setPartiesList(PartyResponse.data.Data)
            if (AddNew) {
                const response = await GenerateMaxTrNo(CompID, TrType)
                if (FormName === "Purchase") {
                    setItemsList(_ItemsArrray)
                }
                setInvNo(response.data.Data[0].TrNo)
                setTempItemOptions(_ItemsArrray)
                setShowLoader(false)
            }
            else {
                EditInvoice(TrID, PartyResponse.data.Data, _ItemsArrray)
                // setLoading(false)
            }
            // fetchItems()

        }
    }, [FormName, StockType, AddNew, rerender])

    const SaveValidate = () => {
        if (!SelectedParty) {
            setErrorMsg({ PartyError: 'Select Any Party' })
            document.getElementById('disable-clearable').focus()
            return false;
        }
        if (ItemDetailData.length === 0) {
            Swal.fire(
                'OOPS!',
                `No Item Selected !`,
                'Info'
            );
            return false;
        }
        if (TotalAmount < 1) {
            setErrorMsg({ TotalAmountError: 'Amount Must be greater than zero' })
            document.getElementById('disable-clearable').focus()
            return false;
        }
        setErrorMsg({ PartyError: '', TotalAmountError: '' })
        return true;
    }
    const SaveInvoice = async () => {
        if (SaveValidate()) {
            setLoading(true)
            const MasterObj = {
                TrID: TrID,
                TrNo: InvNo,
                TrDate: FromDate,
                CompID: CompID,
                PartyID: SelectedParty.PartyID,
                TrType: FormName === "Purchase" ? "Stock In" : "Stock Out",
                Remarks: Remarks,
                UserID: UserID,
                Amount: TotalAmount,
                IsCancel: IsCancel ? 1 : 0,
            }
            const response = await SaveTransaction(MasterObj, ItemDetailData, deleterowData, AddNew)
            console.log(response)
            if (response.status === 200) {
                if (response.data.status) {
                    const _TrID = response.data.TrID
                    setShowLoader(true)
                    EditInvoice(_TrID, PartiesList, ItemsList)
                    let obj = {
                        AddNew: false,
                        TrID: _TrID,
                        TrData: SelectedMonth
                    }
                    dispatch(setFormData(obj))
                    toast.success(response.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setLoading(false)
                }
                else {
                    setLoading(false)
                    Swal.fire(

                        'OOPS!',
                        `${response.data.message}`,
                        'Info'
                    );
                }

            }
        }
    }
    const EditInvoice = async (TrID, Parties, _ItemsArrray) => {
        const response = await SelectTrMaster(TrID, CompID)
        if (response) {
            setInvNo(response.data.Data[0].TrNo)
            setFromDate(response.data.Data[0].TrDate)
            // const _SelectedParty = PartyResponse.data.Data.find(x => x.PartyID === response.data.Data[0].PartyID)
            const _SelectedParty = Parties.find(x => x.PartyID === response.data.Data[0].PartyID)
            setSelectedParty(_SelectedParty)
            setRemarks(response.data.Data[0].Remarks)
            setIsCancel(response.data.Data[0].IsCancel)
            setPost(response.data.Data[0].Post)
            // setLoading(false)
        }
        const DetailResponse = await SelectTrDetail(TrID)
        if (DetailResponse) {
            DetailResponse.data.Data.map((value, index) => {
                value.SrNo = index + 1
                value.Qty = Math.abs(value.Qty)

            })
            _ItemsArrray = _ItemsArrray.filter((obj2) => !DetailResponse.data.Data.some((obj1) => obj1.ItemID === obj2.ItemID));
            setItemDetailData(DetailResponse.data.Data)
            TotalQtyAmount(DetailResponse.data.Data)
            setItemsList(_ItemsArrray)
        }
        setDeleterowData([])
        setShowLoader(false)
    }
    const validate = () => {
        if (!SelectedItem) {
            setErrorMsg({ ItemError: 'Item Required' })
            document.getElementById('disable-clearable').focus()
            return false;
        }
        if (Qty < 1) {
            setErrorMsg({ QtyError: 'Qty Must be greater than zero' })
            document.getElementById('disable-clearable').focus()
            return false;
        }
        if (Rate < 1) {
            setErrorMsg({ RateError: 'Rate Must be greater than zero' })
            document.getElementById('disable-clearable').focus()
            return false;
        }
        if (Amount < 1) {
            setErrorMsg({ AmountError: 'Amount Must be greater than zero' })
            document.getElementById('disable-clearable').focus()
            return false;
        }
        setErrorMsg({ ItemError: '', QtyError: '', RateError: '', AmountError: '' })
        return true;
    }
    const AddtoGrid2 = (index) => {
        debugger
        if (validate()) {
            let IsItemExist = false, alreadyAdded = false

            if (ItemDetailData.length > 0) {
                const ItemFound = ItemDetailData.find(x => x.Edited === true)
                if (ItemFound) {
                    if (ItemFound.Edited) {
                        const index = ItemDetailData.findIndex(x => x.Edited === true)
                        const Data = [...ItemDetailData];
                        const obj = {
                            SrNo: ItemFound.SrNo,
                            ItemName: SelectedItem.ItemName,
                            ItemID: SelectedItem.ItemID,
                            Qty,
                            Rate,
                            Amount,
                            Edited: false,
                            TrDID: TRDID,
                        }
                        Data[index] = obj
                        setItemDetailData(Data)
                        ClearControls()
                        TotalQtyAmount(Data)
                        alreadyAdded = true
                    }
                    else {
                        IsItemExist = true
                    }
                }
            }
            if (!alreadyAdded) {
                if (!IsItemExist) {
                    const Data = [...ItemDetailData];
                    const obj = {
                        SrNo: Data.length + 1,
                        ItemName: SelectedItem.ItemName,
                        ItemID: SelectedItem.ItemID,
                        Qty,
                        Rate,
                        Amount,
                        Edited: false,
                        TrDID: TRDID,
                    }
                    const newData = [...Data, obj]
                    setItemDetailData(newData)
                    ClearControls()
                    TotalQtyAmount(newData)
                }
                else {
                    Swal.fire(

                        'OOPS!',
                        `${SelectedItem.ItemName} already exists`,
                        'Info'
                    );
                }
            }
        }
    }
    const AddtoGrid = (index) => {

        if (validate()) {
            let IsItemExist = false, alreadyAdded = false

            if (ItemDetailData.length > 0) {

                const _ItemFound = ItemDetailData.find(x => x.Edited === true)

                if (_ItemFound && _ItemFound.Edited) {
                    const index = ItemDetailData.findIndex(x => x.Edited === true)
                    const Data = [...ItemDetailData];
                    const obj = {
                        SrNo: _ItemFound.SrNo,
                        ItemName: SelectedItem.ItemName,
                        ItemID: SelectedItem.ItemID,
                        Qty,
                        Rate,
                        Amount,
                        Edited: false,
                        TrDID: TRDID,
                    }
                    Data[index] = obj
                    setItemDetailData(Data)
                    ClearControls()
                    TotalQtyAmount(Data)
                    // alreadyAdded = true
                } else {
                    const Data = [...ItemDetailData];
                    const obj = {
                        SrNo: Data.length + 1,
                        ItemName: SelectedItem.ItemName,
                        ItemID: SelectedItem.ItemID,
                        Qty,
                        Rate,
                        Amount,
                        Edited: false,
                        TrDID: TRDID,
                    }
                    const newData = [...Data, obj]
                    setItemDetailData(newData)
                    ClearControls()
                    TotalQtyAmount(newData)
                }
            } else {
                const obj = {
                    SrNo: 1,
                    ItemName: SelectedItem.ItemName,
                    ItemID: SelectedItem.ItemID,
                    Qty,
                    Rate,
                    Amount,
                    Edited: false,
                    TrDID: TRDID,
                }
                const newData = [obj]
                setItemDetailData(newData)
                ClearControls()
                TotalQtyAmount(newData)
            }
            const _updatedItemList = ItemsList.filter(item => item.ItemID !== SelectedItem.ItemID)
            setItemsList(_updatedItemList)
            // if (!alreadyAdded) {
            //     if (!IsItemExist) {
            //         const Data = [...ItemDetailData];
            //         const obj = {
            //             SrNo: Data.length + 1,
            //             ItemName: SelectedItem.ItemName,
            //             ItemID: SelectedItem.ItemID,
            //             Qty,
            //             Rate,
            //             Amount,
            //             Edited: false,
            //             TrDID: TRDID,
            //         }
            //         const newData = [...Data, obj]
            //         setItemDetailData(newData)
            //         ClearControls()
            //         TotalQtyAmount(newData)
            //     }
            //     else {
            //         Swal.fire(

            //             'OOPS!',
            //             `${SelectedItem.ItemName} already exists`,
            //             'Info'
            //         );
            //     }
            // }
        }
    }
    const handleClick = () => {
        history.push("/PurchaseSale");
        // setLoading(false);
    }
    const ClearControls = () => {
        setSelectedItem(null)
        setLastPurchaseRateParty(" ")
        setQty(0)
        setRate(0)
        setAmount(0)
        setTRDID(0)
        setStkQty(0)
    }
    const TotalQtyAmount = (array) => {
        let Qty = 0, Amt = 0;

        array.map(value => {
            Qty += parseFloat(value.Qty);
            Amt += parseFloat(value.Amount);
        })
        setTotalAmount(Amt)
        setTotalQty(Qty)
    }
    async function fetchLastPurRate(ItemID) {
        const TrType = FormName === "Purchase" ? "Stock In" : "Stock Out"
        const response = await LastPurchaseRate(CompID, TrType, ItemID)
        if (response.data && response.data.Data && response.data.Data.length > 0) {
            // setLastPurchaseRateParty("Rate: " + response.data.Data[0].LastPurchaseRate + "/-" + "   " + "From: " + response.data.Data[0].PartyName)
            setLastPurchaseRateParty(
                "Rate: " +
                response.data.Data[0].LastPurchaseRate +
                "/-" +
                "   " +
                (FormName === "Purchase" ? "From: " : "To: ") +
                response.data.Data[0].PartyName
            );
        }
        else {
            setLastPurchaseRateParty(" ")
        }
    }

    async function fetchAverageRate(ItemID) {
        const response = await LastAveragePurRate(CompID, ItemID)
        if (response.data && response.data.Data && response.data.Data.length > 0) {
            setRate(response.data.Data[0].AverageRate)
        }
        else {
            setRate(" ")
        }
    }
    async function fetchItems() {
        const response = await SelectItems(CompID, 0, 1)
        setItemsList(response.data.Data)
    }
    async function post_Invoice() {
        const response = await InvoicePost(TrID, CompID)
        if (response.status === 200) {
            if (response.data.status) {
                setRerender(true)
                let obj = {
                    AddNew: false,
                    TrID: TrID,
                    TrData: SelectedMonth
                }
                dispatch(setFormData(obj))
                toast.success(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
            else {
                Swal.fire(

                    'OOPS!',
                    `${response.data.message}`,
                    'Info'
                );
            }


        }
        // let obj = {
        //     AddNew: false,
        //     TrID: TrID,
        //     TrData: SelectedMonth
        // }
        // dispatch(setFormData(obj))
    }
    function CalculateAmount(Qty, Rate) {
        const Amount = Qty * Rate;
        setAmount(parseFloat(Amount.toFixed(2)))
    }
    const DeleteTrDetail = (rowData) => {
        if (rowData.TrDID > 0) {
            const deleted = [...deleterowData]
            deleted.push(rowData)
            const Data = ItemDetailData.filter(x => x.TrDID != rowData.TrDID)
            setItemDetailData(Data)
            TotalQtyAmount(Data)
            setDeleterowData(deleted)
        }
        else {
            const Item_ID = rowData.ItemID
            const Data = ItemDetailData.filter(x => x.ItemID != Item_ID)
            setItemDetailData(Data)
            TotalQtyAmount(Data)
        }
    }
    const handleDelete = (rowData) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this data!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Delete This Item!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                DeleteTrDetail(rowData)
            }
        });
    };
    const columns = [
        {
            name: "SrNo",
            label: "Sr#",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.label}</div>
                    )
                },
            }
        },
        {
            name: "ItemName",
            label: "Item",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.label}</div>
                    )
                },
            }
        },
        {
            name: "Qty",
            label: "Qty",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.label}</div>
                    )
                },
            }
        },
        {
            name: "Rate",
            label: "Rate",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.label}</div>
                    )
                },
            }
        },
        {
            name: "Amount",
            label: "Amount",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.label}</div>
                    )
                },
                // customBodyRenderLite: (dataIndex, rowIndex) => {
                //     const amount = ItemDetailData[dataIndex]?.Amount;
                //     const formattedAmount = amount ? separateNumber(parseFloat(amount).toFixed(2)) : "";
                //     return <div>{formattedAmount}</div>;
                // },
            }
        },

        {
            name: "Action",
            label: "Action",
            options: {
                filter: false,
                sort: false,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.name}</div>
                    )
                },
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    if (ItemDetailData[dataIndex] != null)
                        return (
                            <>
                                <Link
                                    to={"#"}
                                    onClick={e => {
                                        if (Post === 0) {
                                            setQty(ItemDetailData[dataIndex].Qty)
                                            setRate(ItemDetailData[dataIndex].Rate)
                                            setAmount(ItemDetailData[dataIndex].Amount)
                                            const _SelectedItem = ItemsList.find(x => x.ItemID === ItemDetailData[dataIndex].ItemID)
                                            setSelectedItem(_SelectedItem)
                                            setTRDID(ItemDetailData[dataIndex].TrDID)
                                            const _ItemDetailData = [...ItemDetailData]
                                            _ItemDetailData[dataIndex].Edited = true
                                            setItemDetailData(_ItemDetailData)
                                        }
                                        else {
                                            // e.preventDefault();
                                            // alert("You cannot change");
                                            Swal.fire(
                                                'OOPS!',
                                                `Posted Inv. Can't be change!`,
                                                'Info'
                                            );
                                            // return false;
                                        }

                                    }}
                                >
                                    <Edit className="IconsColor popup-button" />
                                </Link>
                                &ensp;
                                <Link
                                    to={"#"}
                                    onClick={e => {
                                        if (Post === 0) {
                                            handleDelete(ItemDetailData[dataIndex])
                                        }
                                        else {
                                            Swal.fire(
                                                'OOPS!',
                                                `Posted Inv. Can't be change!`,
                                                'Info'
                                            );
                                        }

                                    }}
                                >
                                    <Delete className="IconsColor popup-button" />
                                </Link>
                            </>
                        );
                }
            }
        },
    ];
    const EditAction = async (dataIndex) => {
        debugger
        if (Post === 0) {
            setQty(ItemDetailData[dataIndex].Qty)
            setRate(ItemDetailData[dataIndex].Rate)
            setAmount(ItemDetailData[dataIndex].Amount)

            setTRDID(ItemDetailData[dataIndex].TrDID)
            const _ItemDetailData = [...ItemDetailData]
            _ItemDetailData[dataIndex].Edited = true
            setItemDetailData(_ItemDetailData)

            // add item back to DD

            const response = await SelectItemGridEdit(CompID, ItemDetailData[dataIndex].ItemID)
            const _selectedItem = response.data.Data[0]
            const _ItemsList = [...ItemsList, _selectedItem]
            setSelectedItem(_selectedItem)
            setItemsList(_ItemsList)
        }
        else {
            Swal.fire(
                'OOPS!',
                `Posted Inv. Can't be change!`,
                'Info'
            );
        }

    }
    const DeleteAction = async (dataIndex) => {
        if (Post === 0) {
            handleDelete(ItemDetailData[dataIndex])
            const response = await SelectItemGridEdit(CompID, ItemDetailData[dataIndex].ItemID)
            const _selectedItem = response.data.Data[0]
            const _ItemsList = [...ItemsList, _selectedItem]
            setItemsList(_ItemsList)
        }
        else {
            Swal.fire(
                'OOPS!',
                `Posted Inv. Can't be change!`,
                'Info'
            );
        }
    }
    const classes = useStyles();
    return ReactDom.createPortal(
        <div className="col-md-12">
            <ToastContainer />
            <div class="card" >
                <div class="card-body">
                    <div className='row' style={{ padding: '1%' }} >
                        <div className="col-md-6 col-lg-6 col-sm-6">
                            <h1>{FormName}</h1>
                        </div>
                        <div className="col-md-6 col-lg-6 col-sm-6" style={{ textAlign: "right" }}>
                            {
                                !AddNew && UserType === "Admin" && IsCancel === 0 &&
                                <LoadingButton
                                    className={`${classes.root} popup-button`}
                                    style={{ marginRight: Post === 1 ? "-6px" : "" }}
                                    size="small"
                                    color="secondary"
                                    onClick={e => {
                                        post_Invoice(TrID)
                                    }}
                                    disabled={Post === 1 ? true : false}
                                    loadingPosition="start"
                                    startIcon={<PostAddIcon />}
                                    variant="contained"
                                >
                                    <span>{Post === 0 ? "Post" : "Posted"}</span>
                                </LoadingButton>
                            }
                            &nbsp;&nbsp;
                            {
                                Post === 0 &&
                                <LoadingButton
                                    className={`${classes.root} popup-button`}
                                    size="small"
                                    color="secondary"
                                    onClick={SaveInvoice}
                                    loading={loading}
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="contained"
                                >
                                    {loading ? "Saving" : "Save"}
                                    {/* <span>Save</span> */}
                                </LoadingButton>
                            }
                            &nbsp;&nbsp;
                            {
                                !AddNew &&
                                <LoadingButton
                                    className={`${classes.root} popup-button`}
                                    // style={{ color: "white", backgroundColor: "#293042", marginTop: "5px" }}
                                    size="small"
                                    color="secondary"
                                    onClick={e => {
                                        var mtoken = sessionStorage.getItem('token')
                                        localStorage.setItem('token', sessionStorage.getItem('token'))
                                        var obj = {
                                            InvoiceNumber: InvNo,
                                            Comp_Name: CompName,
                                            Comp_Add: CompAdd,
                                            Party_Name: SelectedParty.PartyName,
                                            PartyPhoenNumber: SelectedParty.PhoneNo,
                                            InvoiceDate: FromDate,
                                            Grid_Data: ItemDetailData,
                                            TotalQuantity: TotalQty,
                                            TtlAmt: TotalAmount,
                                            FormName: FormName,
                                        }
                                        localStorage.setItem("InvoiceReportData", JSON.stringify(obj))
                                        window.open("/InvoiceReportPrint", "_blank")
                                        // showLoader()
                                    }}
                                    // loading={loading}
                                    loadingPosition="start"
                                    startIcon={<Print />}
                                    variant="contained"
                                >
                                    <span>Print</span>
                                </LoadingButton>
                            }
                            &nbsp;&nbsp;
                            {
                                !AddNew && FormName === "Purchase" &&
                                <LoadingButton
                                    className={`${classes.root} popup-button`}
                                    // style={{ color: "white", backgroundColor: "#293042", marginTop: "5px" }}
                                    size="small"
                                    color="secondary"
                                    onClick={e => {
                                        var mtoken = sessionStorage.getItem('token')
                                        localStorage.setItem('token', sessionStorage.getItem('token'))
                                        var obj = {
                                            InvoiceNumber: InvNo,
                                            Comp_Name: CompName,
                                            Comp_Add: CompAdd,
                                            Party_Name: SelectedParty.PartyName,
                                            PartyPhoenNumber: SelectedParty.PhoneNo,
                                            InvoiceDate: FromDate,
                                            Grid_Data: ItemDetailData,
                                            TotalQuantity: TotalQty,
                                            TtlAmt: TotalAmount,
                                            FormName: FormName,
                                        }
                                        localStorage.setItem("InvoiceStickerPrintData", JSON.stringify(obj))
                                        window.open("/InvoiceStickerPrint", "_blank")
                                        // showLoader()
                                    }}
                                    // loading={loading}
                                    loadingPosition="start"
                                    startIcon={<Print />}
                                    variant="contained"
                                >
                                    <span>Sticker</span>
                                </LoadingButton>
                            }

                            &nbsp;&nbsp;
                            {/* </div>
                        <div className="col-md-1 col-lg-1 col-sm-1" > */}
                            <LoadingButton
                                className={`${classes.root} popup-button`}
                                size="small"
                                color="secondary"
                                onClick={handleClick}
                                // loading={loading}
                                loadingPosition="start"
                                startIcon={<Cancel />}
                                variant="contained"
                            >
                                <span>Back</span>
                            </LoadingButton>
                        </div>
                    </div>
                    <div className='row' style={{ padding: '1%' }}>
                        <div className="col-md-2 col-lg-2 col-sm-2">
                            <TextField
                                name="InvNo"
                                id="InvNo"
                                label="Invoice#"
                                value={InvNo}
                                fullWidth
                                inputProps={{ readOnly: true }}
                            />
                            <span style={{ color: "red" }}>{ErrorMsg.TypeError}</span>
                        </div>
                        <div className="col-md-2 col-lg-2 col-sm-2">
                            <TextField
                                name="FromDate"
                                id="FromDate"
                                label="Invoice Date"
                                type="date"
                                value={FromDate}
                                onChange={e => {
                                    setFromDate(e.target.value)
                                    // setErrorMsg({ ...ErrorMsg, NameError: '' })
                                }}
                                fullWidth

                            />
                        </div>
                        <div className="col-md-3 col-lg-3 col-sm-3" style={{ marginTop: "4px" }}>
                            <Autocomplete
                                id="disable-clearable"
                                options={PartiesList}
                                getOptionLabel={(option) => option.PartyName}
                                value={SelectedParty}
                                disabled={ItemDetailData.length > 0}
                                onChange={(e, value) => {
                                    if (FormName !== "Purchase") {
                                        const filtered = TempItemOptions.filter(item => item.PartyID === value.PartyID);
                                        setItemsList(filtered)
                                    }
                                    setSelectedParty(value)
                                    setErrorMsg({ ...ErrorMsg, PartyError: '' })
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label={FormName === "Purchase" ? "Select Party" : "Select Department"} variant="standard" />
                                )}
                                fullWidth
                            />
                            <span style={{ color: "red" }}>{ErrorMsg.PartyError}</span>
                        </div>
                        <div className="col-md-2 col-lg-2 col-sm-2">
                            <TextField
                                name="TotalQty"
                                id="TotalQty"
                                label="Total Qty"
                                value={TotalQty}
                                inputProps={{ readOnly: true }}
                                fullWidth

                            />
                            <span style={{ color: "red" }}>{ErrorMsg.TypeError}</span>
                        </div>
                        <div className="col-md-2 col-lg-2 col-sm-2">
                            <TextField
                                name="TotalAmount"
                                id="TotalAmount"
                                label="Total Amount"
                                value={separateNumber(TotalAmount.toFixed(2))}
                                inputProps={{ readOnly: true }}
                                fullWidth

                            />
                            <span style={{ color: "red" }}>{ErrorMsg.TotalAmountError}</span>
                        </div>
                    </div>
                    <div className='row' style={{ padding: '1%', marginTop: "-1%" }}>
                        <div className="col-md-3 col-lg-3 col-sm-3" style={{ marginTop: "4px" }}>
                            <Autocomplete
                                id="disable-clearable"
                                disableClearable
                                options={ItemsList}
                                getOptionLabel={(option) => option.ItemName}
                                value={SelectedItem}
                                onChange={(e, value) => {
                                    if (FormName === "Consumption") {
                                        fetchAverageRate(value.ItemID)
                                    }
                                    else {
                                        setRate(parseFloat(value.Rate))
                                    }
                                    fetchLastPurRate(value.ItemID)
                                    setSelectedItem(value)
                                    CalculateAmount(Qty, value.Rate)
                                    setErrorMsg({ ...ErrorMsg, ItemError: '' })
                                    setStkQty(value.StockQty)
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Select Item" variant="standard" />
                                )}
                                fullWidth
                            />
                            <span style={{ color: "red" }}>{ErrorMsg.ItemError}</span>
                        </div>
                        <div className="col-md-2 col-lg-2 col-sm-2">
                            <TextField
                                name="Qty"
                                id="Qty"
                                label="Qty"
                                value={Qty}
                                onChange={e => {
                                    const _Qty = parseFloat(e.target.value)
                                    setQty(_Qty)
                                    CalculateAmount(_Qty, Rate)
                                    setErrorMsg({ ...ErrorMsg, QtyError: '' })
                                }}
                                fullWidth

                            />
                            <span style={{ color: "red" }}>{ErrorMsg.QtyError}</span>
                        </div>
                        <div className="col-md-2 col-lg-2 col-sm-2">
                            <TextField
                                name="Rate"
                                id="Rate"
                                label={FormName === "Purchase" ? "Rate" : "Weighted Avg. Rate"}
                                value={Rate}
                                onChange={e => {
                                    setRate(parseFloat(e.target.value))
                                    CalculateAmount(Qty, e.target.value)
                                    setErrorMsg({ ...ErrorMsg, RateError: '' })
                                }}
                                fullWidth

                            />
                            <span style={{ color: "red" }}>{ErrorMsg.RateError}</span>

                        </div>
                        <div className="col-md-2 col-lg-2 col-sm-2">
                            <TextField
                                name="Amount"
                                id="Amount"
                                label="Amount"
                                value={Amount}
                                inputProps={{ readOnly: true }}
                                fullWidth

                            />
                            <span style={{ color: "red" }}>{ErrorMsg.AmountError}</span>
                        </div>
                        {
                            Post === 0 &&
                            <div className="col-md-2 col-lg-2 col-sm-2" style={{ marginTop: "17px" }}>
                                <button
                                    // style={{ backgroundColor:"#293042" }}
                                    type="button"
                                    onClick={e => {
                                        AddtoGrid()

                                    }}
                                    className="ThemeHeader popup-button"
                                > <SaveAltIcon /> </button>
                                &nbsp;&nbsp;&nbsp;
                                <button className="ThemeHeader popup-button"
                                    onClick={e => {
                                        ClearControls()
                                    }}
                                > <Close /></button>
                            </div>
                        }

                    </div>
                    <div className='row' style={{ padding: '1%', marginTop: "-1%" }}>
                        <div className="col-md-4 col-lg-4 col-sm-4">
                            <TextField
                                name="Last Purchase Rate"
                                id="Last Purchase Rate"
                                label="Last Rate"
                                value={LastPurchaseRateParty}
                                inputProps={{ readOnly: true }}
                                fullWidth

                            />
                        </div>
                    </div>

                    <div className='row' style={{ paddingTop: '1%' }}>
                        <Grid
                            ItemDetailData={ItemDetailData} EditAction={EditAction} DeleteAction={DeleteAction} />
                    </div>
                    <div className='row' style={{ paddingTop: '1%', paddingBottom: '1%' }}>
                        <Row>
                            {/* {
                                !AddNew && */}
                            <>

                                {/* <Col sm={2} md={2} xl={2} lg={2} style={{ paddingTop: '15px' }}>
                                    <FormGroup style={{ marginLeft: "20px" }}>
                                        <FormControlLabel control={<Switch checked={IsCancel}
                                            onChange={e => {
                                                setIsCancel(e.target.checked)
                                            }}
                                        />} label="Cancel" />
                                    </FormGroup>
                                </Col> */}
                                <Col sm={2} md={2} xl={2} lg={2} style={{ paddingTop: '15px' }}>
                                    <FormGroup style={{ marginLeft: "20px" }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={IsCancel}
                                                    onChange={e => {
                                                        setIsCancel(e.target.checked)
                                                    }}
                                                    style={{ color: "#293042" }} // Set the color of the checkbox
                                                />
                                            }
                                            label="Cancel"
                                        />
                                    </FormGroup>
                                </Col>

                            </>
                            {/* } */}
                            <Col sm={10} md={10} xl={10} lg={10}>
                                <TextField style={{ marginLeft: "6px" }}
                                    name="Remarks"
                                    id="Remarks"
                                    label="Remarks"
                                    value={Remarks}
                                    onChange={e => {
                                        setRemarks(e.target.value)
                                    }}
                                    fullWidth

                                />
                            </Col>
                        </Row>
                    </div>

                    <GlobalLoader
                        open={showLoader}
                    />
                    {/* <PartyModal
                        handleClose={closeModal}
                        open={Open}
                        PartyID={PartyID}
                        fetchData={fetchData}
                        SelectedGroup={SelectedGroup}
                        partyname={Party_Name}
                        phno={Phone_Num}
                        email={E_mail}
                        addr={Addrress}
                        isactive={Is_active}
                    /> */}
                </div>
            </div>
            {/* <Loader
                show={IsLoader}
                hide={hideLoader}
            /> */}
        </div >
        ,
        document.getElementById('content')
    )
}

export default PurchaseInvoice