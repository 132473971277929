import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import ReactDom from 'react-dom';
import { hide_divs } from './../../Services/Common';

function setPageSize(cssPageSize) {
    const style = document.createElement('style');
    style.innerHTML = `
        @page {
            margin-top: 2%;
            margin-bottom: 2%;
        }
    `;
    style.id = 'page-orientation';
    document.head.appendChild(style);
}

function InvoiceStickerPrint() {
    const [Invoice_Num, setInvoice_Num] = useState(null);
    const [Invoice_Date, setInvoice_Date] = useState('');
    const [InvDetailData, setInvDetailData] = useState([]);

    useEffect(() => {
        hide_divs();
        var _ReportData = JSON.parse(localStorage.getItem('InvoiceStickerPrintData'));
        if (_ReportData) {
            setInvoice_Num(_ReportData.InvoiceNumber);
            setInvoice_Date(_ReportData.InvoiceDate);
            setInvDetailData(_ReportData.Grid_Data);
            setPageSize('Portrait');
        }
        // localStorage.removeItem("InvoiceStickerPrintData")
    }, []);

    const renderStickers = () => {
        let stickers = [];

        InvDetailData.forEach((item, index) => {
            const itemName = item.ItemName; // Assuming this field exists in Grid_Data
            const itemQty = item.Qty; // Assuming this field exists in Grid_Data

            // Generate stickers based on the quantity
            for (let i = 0; i < itemQty; i++) {
                stickers.push(
                    <Row key={`${itemName}-${i}`} style={{ marginTop: '2%', color: 'black', marginLeft: '1.5%', border: "2px solid black" }}>
                        <Col sm={6} style={{ fontWeight: 'bold', textAlign: "right" }}>
                            Inv#:
                        </Col>
                        <Col sm={6} style={{ textAlign: "left" }}> {Invoice_Num}
                        </Col>
                        <Col sm={6} style={{ fontWeight: 'bold', textAlign: "right" }}>
                            Inv Date:
                        </Col>
                        <Col sm={6} style={{ textAlign: "left" }}> {Invoice_Date}
                        </Col>
                        <Col sm={6} style={{ fontWeight: 'bold', textAlign: "right" }}>
                            Item Name:
                        </Col>
                        <Col sm={6} style={{ textAlign: "left" }}> {itemName}
                        </Col>
                        <Col sm={6} style={{ fontWeight: 'bold', textAlign: "right" }}>
                            Item No :
                        </Col>
                        <Col sm={6} style={{ textAlign: "left" }}> {i+1} of {itemQty}
                        </Col>
                    </Row>
                );
            }
        });

        return stickers;
    };

    return ReactDom.createPortal(
        <Row className='report-font' style={{ fontSize: '18px' }}>
            <Col>
                <div>{renderStickers()}</div>
            </Col>
        </Row>,
        document.getElementById('body')
    );
}

export default InvoiceStickerPrint;
